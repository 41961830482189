<template>
  <v-container fluid fill-height class="background-login">
    
    <v-row>
      <v-col :cols="$vuetify.breakpoint.lgAndUp? '6' : '12'" align="center" justify="center">

        <v-card width="350">

          <v-card-title
          class="justify-center pb-0">
              <v-img
                  class="mx-2 mb-0 mouse-pointer"
                  src="@/assets/img/logo/logo_mpdc.png"
                  max-width="140"
                  contain
              ></v-img>
          </v-card-title>

          <v-card-title
          class="justify-center pb-5 mb-0 h6"
          >
              <h5>Marine Park Data Center <br> (MPDC)</h5>
          </v-card-title>

          <v-card-text>
              <v-container class="py-0">
                <form @submit.prevent="onSignin" :state="null">

                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                      v-model="formLogin.email"
                      :rules="rules.email"
                      color="primary"
                      label="Email"
                      prepend-inner-icon="mdi-account"
                      name="Math.random()"
                      autocomplete="text"
                      required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-text-field
                      v-model="formLogin.password"
                      :rules="rules.password"
                      color="primary"
                      label="Password"
                      type="password"
                      prepend-inner-icon="mdi-lock-outline"
                      name="Math.random()"
                      autocomplete="new-password"
                      required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-5">
                      <v-btn
                      color="primary"
                      width="100%"
                      :loading="loading"
                      @click="loginSystem()"
                      >
                        Login
                      </v-btn>
                    </v-col>
                  </v-row>

                </form>
              </v-container>
          </v-card-text>

        </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { bus } from '@/main';

export default {

  data: () => ({

    formLogin: {
      email: "",
      password: "",
    },

    rules: {
      email: [val => (val || '').length > 0 || 'This field is required'],
      password: [val => (val || '').length > 0 || 'This field is required'],
    },
    
  }),

  mounted() {
     console.log(this.$store.getters);
  },

  methods: {

    loginSystem(){

      if(this.formLogin.email != "" || this.formLogin.password != ""){
        this.$store.dispatch('signUserIn', { username: this.formLogin.email, password: this.formLogin.password });
      }
      else if(this.formLogin.email === "" || this.formLogin.password === ""){
        bus.$emit('triggerMiniPopup', {focus: 'inform', info: {
          icon: 'mdi-login-variant',
          title: 'Login',
          context: 'Please enter email & password',
          status: 'error',
          notes: '',
          info: null,
          color: 'error',
        }});
      }
      else{
        bus.$emit('triggerMiniPopup', {focus: 'inform', info: {
          icon: 'mdi-login-variant',
          title: 'Login',
          context: 'Please enter email & password',
          status: 'error',
          notes: '',
          info: null,
          color: 'error',
        }});
      }

      
      

    }
    
  },

  computed: {
      //user() {
          //return this.$store.getters.user
      //},
      loading() {
          return this.$store.getters.loading
      },
      error() {
          return this.$store.getters.error
      },
      //token() {
          //return this.$store.getters.user.token
      //}
  },

  created (){
      bus.$on('triggerLogin', (data) => {

          if(data.focus == "errorMessageLogin"){
            bus.$emit('triggerMiniPopup', {focus: 'inform', info: {
              icon: 'mdi-login-variant',
              title: 'Login',
              context: data.info,
              status: 'error',
              notes: '',
              info: null,
              color: 'error',
            }});
          }

      })
  },

  

};
</script>

<style lang="scss">
@import '~scss/main';

.background-login{
    background-image: url("~@/assets/img/wallpaper/bg-2.jpg") !important;
    background-size:cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

</style>


